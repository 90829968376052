.invalidated-question-popup ul {
    margin: 0;
    padding: 0;
    text-align: left;
}

.invalidated-question-popup ul li {
    list-style: none;
    padding: 0;
    margin: 0 0 4px;
    color: #e64646;
}

.invalidated-question-popup ul li:last-child {
    margin: 0;
}
