.invalidated-files-popup {
    font-size: 14px;
    line-height: 20px;
}

.invalidated-files-popup ul {
    margin: 0;
    padding: 0 0 0 12px;
}

.invalidated-files-popup li {
    list-style: none;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.invalidated-files-popup li span {
    max-width: 100%;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.invalidated-files-popup__titles {
    margin: 0 0 6px;
}

.invalidated-files-popup__cross {
    margin: 1px 4px 0 0;
}

.invalidated-files-popup__section {
    margin: 0 0 8px;
}

.invalidated-files-popup__section:last-child {
    margin: 0;
}
