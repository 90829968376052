.submit-phone-suggestion-modal {
    padding: 0 16px 32px;
}

.submit-phone-suggestion-modal__text {
    font-size: 13px;
    line-height: 16px;
    color: #5f5d5d;
}

.submit-phone-suggestion-modal__control {
    margin: 0 0 16px;
}

.submit-phone-suggestion-modal__input:disabled {
    background: var(--control_background);
    color: var(--control_color);
    border: 1px solid var(--control_border_color);
    cursor: text;
    opacity: 1;
}

.submit-phone-suggestion-modal__control:last-child {
    margin: 0;
}

.submit-phone-suggestion-modal__submit[disabled] {
    background: #cccccc;
    opacity: 1;
    cursor: not-allowed;
}
