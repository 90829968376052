.notice {
    background: rgba(105, 105, 105, 0.95);
    border-radius: 10px;
    padding: 12px 16px;
    font-size: 14px;
    line-height: 20px;
    position: fixed;
    z-index: 10;
    max-width: 300px;
    width: 100%;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 60px;
    color: #ffffff;
    display: none;
    opacity: 0;
}
