.discussion-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 0 30px;
}

.discussion-empty__icon {
    margin: 0 0 8px;
}

.discussion-empty__title {
    font-size: 16px;
    line-height: 24px;
    color: var(--text_color);
    text-align: center;
}
