:root {
    --file_list_item_background: #ffffff;
}

body[scheme="client_dark"] {
    --file_list_item_background: #454647;
}

.file-list-item {
    list-style: none;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    background: var(--file_list_item_background);
    height: 32px;
}

.file-list-item__inner {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: var(--text_color);
    height: 100%;
    padding: 0 8px 0 0;
}

.file-list-item__name {
    color: #528bcc;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.file-list-item__link {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 1;
    top: 0;
    left: 0;
    cursor: pointer;
}

.file-list-item__chunk {
    display: flex;
    align-items: center;
    height: 100%;
}

.file-list-item__chunk:first-child {
    flex: 1;
    overflow: hidden;
}

.file-list-item__icon {
    height: 100%;
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.file-list-item__size {
    margin: 0 8px;
}
