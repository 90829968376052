:root {
    --control_background: #f2f3f5;
    --text_color: #000000;
    --control_border_color: rgba(0, 0, 0, 0.12);
    --blockquote_text_color: #6d6c6c;
    --blockquote_background: #c4c4c440;
    --page_background: #ffffff;
}

body[scheme="client_dark"] {
    --control_background: #232324;
    --text_color: #e1e3e6;
    --control_border_color: rgba(255, 255, 255, 0.12);
    --blockquote_text_color: #e1e3e6;
    --blockquote_background: #454647;
    --page_background: #0a0a0a;
}

body {
    overflow-y: scroll;
    background: var(--page_background);
}

button.disabled {
    background: #cccccc;
}

.contentable {
    font-size: 16px;
    line-height: 24px;
    color: var(--text_color);
}

.contentable a {
    text-decoration: none;
    color: #528bcc;
}

.contentable p {
    margin: 0 0 8px;
}

.contentable ul,
.contentable ol {
    margin: 0 0 8px;
    padding: 0 0 0 32px;
}

.contentable ul li {
    list-style-type: disc;
}

.contentable ul:last-child,
.contentable ol:last-child,
.contentable p:last-child,
.contentable blockquote.blockquote:last-child,
.contentable blockquote.quote:last-child {
    margin: 0;
}

.contentable blockquote.blockquote {
    background: var(--blockquote_background);
    margin: 0 0 8px;
    padding: 12px 16px 12px 12px;
    position: relative;
    border-left: 4px solid rgb(75, 179, 75);
    color: var(--blockquote_text_color);
}

.contentable blockquote.quote {
    background: url('./img/quotes.svg') var(--blockquote_background) no-repeat 11px 18px;
    background-size: 9px 12px;
    margin: 0 0 8px;
    padding: 12px 16px 12px 30px;
    color: var(--blockquote_text_color);
}

.input {
    background: var(--control_background);
    border-radius: 10px;
    height: 44px;
    box-sizing: border-box;
    padding: 12px;
    font-size: 16px;
    line-height: 24px;
    color: var(--text_color);
    border: 1px solid var(--control_border_color);
    outline: none;
    width: 100%;
}

.textarea {
    background: var(--control_background);
    border-radius: 10px;
    border: 1px solid var(--control_border_color);
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.textarea textarea {
    outline: none;
    resize: none;
    height: 100%;
    background: var(--control_background);
    width: 100%;
    border: none;
    border-radius: 10px;
    padding: 12px;
    color: var(--text_color);
    font-size: 16px;
    line-height: 24px;
    box-sizing: border-box;
}

.pointer {
    cursor: pointer;
}

.not-allowed {
    cursor: not-allowed;
}

@keyframes wave {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}
