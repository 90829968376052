.tutorial,
.tutorial__slide,
.tutorial__gallery {
    height: 100%;
}

.tutorial__slide {
    background-repeat: no-repeat;
    background-size: cover;
    padding: 60px 16px 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
}

.tutorial__gallery .Gallery__bullets {
    bottom: 80px;
}

.tutorial__icon {
    width: 100px;
    height: 100px;
    margin: 0 auto 50px;
}

.tutorial__icon img {
    width: 100%;
    height: 100%;
}


.tutorial__text {
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    line-height: 24px;
}

.tutorial__logo {
    width: 170px;
    margin: 0 auto 40px;
}

.tutorial__logo img {
    width: 100%;
}

.tutorial__control {
    text-align: center;
    color: #ffffff;
    width: 100%;
    font-size: 17px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
