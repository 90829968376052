:root {
    --control_background: #f2f3f5;
    --text_color: #000000;
    --control_border_color: rgba(0, 0, 0, 0.12);
    --blockquote_text_color: #6d6c6c;
    --blockquote_background: #c4c4c440;
    --page_background: #ffffff;
}

body[scheme="client_dark"] {
    --control_background: #232324;
    --text_color: #e1e3e6;
    --control_border_color: rgba(255, 255, 255, 0.12);
    --blockquote_text_color: #e1e3e6;
    --blockquote_background: #454647;
    --page_background: #0a0a0a;
}

body {
    overflow-y: scroll;
    background: #ffffff;
    background: var(--page_background);
}

button.disabled {
    background: #cccccc;
}

.contentable {
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    color: var(--text_color);
}

.contentable a {
    text-decoration: none;
    color: #528bcc;
}

.contentable p {
    margin: 0 0 8px;
}

.contentable ul,
.contentable ol {
    margin: 0 0 8px;
    padding: 0 0 0 32px;
}

.contentable ul li {
    list-style-type: disc;
}

.contentable ul:last-child,
.contentable ol:last-child,
.contentable p:last-child,
.contentable blockquote.blockquote:last-child,
.contentable blockquote.quote:last-child {
    margin: 0;
}

.contentable blockquote.blockquote {
    background: #c4c4c440;
    background: var(--blockquote_background);
    margin: 0 0 8px;
    padding: 12px 16px 12px 12px;
    position: relative;
    border-left: 4px solid rgb(75, 179, 75);
    color: #6d6c6c;
    color: var(--blockquote_text_color);
}

.contentable blockquote.quote {
    background: url(/static/media/quotes.4b8379c6.svg) #c4c4c440 no-repeat 11px 18px;
    background: url(/static/media/quotes.4b8379c6.svg) var(--blockquote_background) no-repeat 11px 18px;
    background-size: 9px 12px;
    margin: 0 0 8px;
    padding: 12px 16px 12px 30px;
    color: #6d6c6c;
    color: var(--blockquote_text_color);
}

.input {
    background: #f2f3f5;
    background: var(--control_background);
    border-radius: 10px;
    height: 44px;
    box-sizing: border-box;
    padding: 12px;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    color: var(--text_color);
    border: 1px solid rgba(0, 0, 0, 0.12);
    border: 1px solid var(--control_border_color);
    outline: none;
    width: 100%;
}

.textarea {
    background: #f2f3f5;
    background: var(--control_background);
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border: 1px solid var(--control_border_color);
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.textarea textarea {
    outline: none;
    resize: none;
    height: 100%;
    background: #f2f3f5;
    background: var(--control_background);
    width: 100%;
    border: none;
    border-radius: 10px;
    padding: 12px;
    color: #000000;
    color: var(--text_color);
    font-size: 16px;
    line-height: 24px;
    box-sizing: border-box;
}

.pointer {
    cursor: pointer;
}

.not-allowed {
    cursor: not-allowed;
}

@-webkit-keyframes wave {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}

@keyframes wave {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}

.notice {
    background: rgba(105, 105, 105, 0.95);
    border-radius: 10px;
    padding: 12px 16px;
    font-size: 14px;
    line-height: 20px;
    position: fixed;
    z-index: 10;
    max-width: 300px;
    width: 100%;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 60px;
    color: #ffffff;
    display: none;
    opacity: 0;
}

.questions {
    list-style: none;
    margin: 0;
    padding: 10px 0 10px;
}

.questions-more {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
}

.questions-more__link {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

:root {
    --item_background: #ffffff;
    --item_separator_color: #e6e6e6;
}

body[scheme="client_dark"] {
    --item_background: #19191a;
    --item_separator_color: #313233;
}

.question-item {
    background: #ffffff;
    background: var(--item_background);
    padding: 20px 16px 24px;
    border-bottom: 1px solid #e6e6e6;
    border-bottom: 1px solid var(--item_separator_color);
    color: var(--text_color);
    cursor: pointer;
}

.question-item:last-child {
    border-bottom: none;
}

.question-item__title {
    margin: 0 0 8px;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
}

.question-item__text {
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 10px;
    color: var(--text_color);
}

.question-item__answers {
    font-size: 14px;
    color: #a9a9a9;
}

:root {
    --card_background: #ffffff;
    --detail_form_separator_color: #e6e6e6;
}

body[scheme="client_dark"] {
    --card_background: #19191a;
    --detail_form_separator_color: #313233;
}

.question-card {
    padding: 20px 16px 24px;
    background: #ffffff;
    background: var(--card_background);
    margin: 0 0 24px;
}

.question-card__title {
    font-size: 22px;
    font-weight: bold;
    line-height: 28px;
    margin: 0 0 8px;
    color: var(--text_color);
}

.question-card__content {
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 16px;
}

.client-detail__content--tmp,
.question-card__content--tmp {
    padding: 0 16px;
    position: absolute;
    left: -999999999;
    visibility: hidden;
    z-index: -9999999;
}

.client-detail__inner,
.question-card__frame {
    overflow: hidden;
    position: relative;
}

.question-card__files {
    margin: 0 0 16px;
}

.question-card__frame--closed {
    height: 200px;
}

.client-detail__inner--opened,
.question-card__frame--opened {
    height: auto;
}

.client-detail__inner:after,
.question-card__frame:after {
    content: '';
    display: block;
    width: 100%;
    height: 44px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    display: none;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 3%, #ffffff);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 3%, var(--card_background));
}

.client-detail__inner--shaded:after,
.question-card__frame--shaded:after {
    display: block;
}

.client-detail__opener-container,
.question-card__opener-container {
    display: flex;
    justify-content: flex-end;
    padding: 4px 0 0;
}

.detail-form {
    margin: 16px 0 0;
    padding: 16px 0 0;
    border-top: 1px solid #e6e6e6;
    border-top: 1px solid var(--detail_form_separator_color);
}

.detail-form__title {
    font-size: 16px;
    line-height: 24px;
    color: var(--text_color);
    margin: 0 0 8px;
}

.detail-form__file,
.detail-form__files {
    margin: 0 0 16px;
}

.detail-form__submit[disabled] {
    opacity: 1;
    background: #cccccc;
    cursor: not-allowed;
}

.detail-form__textarea {
    height: 100px;
    margin: 0 0 16px;
}

.detail-form__progress {
    padding: 4px 8px;
}

.detail-form__loader.Spinner {
    color: #ffffff;
}

.client-details {
    margin: 0 0 16px;
    padding: 0;
}

.client-details__item {
    margin: 0 0 16px;
}

.client-detail {
    list-style: none;
    color: var(--text_color);
}

.client-detail__title {
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 8px;
    color: var(--text_color);
}

.client-detail__content {
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 8px;
}

.client-detail__meta {
    color: #909499;
    font-size: 14px;
}

.client-detail__files {
    margin: 0 0 16px;
}

.client-detail__inner--closed {
    height: 70px;
}

:root {
    --file_list_item_background: #ffffff;
}

body[scheme="client_dark"] {
    --file_list_item_background: #454647;
}

.file-list-item {
    list-style: none;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    background: #ffffff;
    background: var(--file_list_item_background);
    height: 32px;
}

.file-list-item__inner {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: var(--text_color);
    height: 100%;
    padding: 0 8px 0 0;
}

.file-list-item__name {
    color: #528bcc;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.file-list-item__link {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 1;
    top: 0;
    left: 0;
    cursor: pointer;
}

.file-list-item__chunk {
    display: flex;
    align-items: center;
    height: 100%;
}

.file-list-item__chunk:first-child {
    flex: 1 1;
    overflow: hidden;
}

.file-list-item__icon {
    height: 100%;
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.file-list-item__size {
    margin: 0 8px;
}

.files-list {
    padding: 0;
}

.files-list__item {
    margin: 0 0 8px;
}

.files-list__item:last-child {
    margin-bottom: 0;
}

.attached-files__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 -8px;
    padding: 0;
}

.attached-files__file {
    margin: 0 8px 8px 0;
    max-width: 100%;
    box-sizing: border-box;
}

.attached-file {
    list-style: none;
    background: #e8e8e8;
    border-radius: 15px;
    height: 24px;
    padding: 0 0 0 8px;
    position: relative;
    overflow: hidden;
}

.attached-file__inner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    position: relative;
}

.attached-file__name {
    color: #656565;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
}

.attached-file__delete {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #afafaf;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin: 0 2px 0 6px;
    cursor: pointer;
}

.attached-file__progress {
    position: absolute;
    width: 30%;
    height: 100%;
    left: 0;
    top: 0;
    -webkit-animation-duration: 1.2s;
            animation-duration: 1.2s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-name: wave;
            animation-name: wave;
    background: rgba(0, 57, 115, 0.17);
    background: linear-gradient(to right, rgba(0,57,115,.17) 8%, rgba(255, 255, 255, 0.3) 18%, rgba(0,57,115,.17) 33%);
    background-size: 800px 104px;
}

.attached-file--done .attached-file__progress {
    background: #4bb34b52;
    -webkit-animation: none;
            animation: none;
}

.attached-file--done .attached-file__name {
    color: #858585;
}

.attached-file--error .attached-file__progress {
    background: #f33b3b85;
}

.attached-file--error .attached-file__name {
    color: #ffffff;
}

.attached-file--small {
    height: 20px;
}

.attached-file--small .attached-file__delete {
    width: 17px;
    height: 17px;
}

.attached-file--small .attached-file__name {
    font-size: 11px;
}

:root {
    --item_background: #ffffff;
    --item_level_2_background: #ebedf0;
}

body[scheme="client_dark"] {
    --item_background: #19191a;
    --item_level_2_background: #0a0a0a;
}

.discussion-item {

}

.discussion-item__main--highlighted {
    background: #ffffff;
    background: var(--item_background);
}

.discussion-item__header {
    margin: 0 0 5px;
    padding: 6px 0 0;
    display: flex;
    align-items: center;
}

.discussion-item__inner {
    padding: 0 16px 24px;
}

.discussion-item__content {
    font-size: 16px;
    line-height: 24px;
    color: var(--text_color);
    margin: 0 0 8px;
}

.discussion-item__files {
    margin: 0 0 16px;
}

.discussion-item__content--tmp {
    padding: 0 16px;
    position: absolute;
    left: -999999999;
    visibility: hidden;
    z-index: -9999999;
}

.discussion-item__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.discussion-item__footer-left {
    display: flex;
    align-items: center;
}

.discussion-item__meta {
    font-size: 14px;
    color: #909499;
}

.discussion-item__reply {
    margin: 0 0 0 10px;
}

.discussion-item__children .discussion-item__inner {
    padding-left: 24px;
}

.discussion-item__children .discussion-item__header {
    padding-left: 16px;
}

.discussion-item__sub-arrow {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    margin: 0 -5px;
}

.discussion-item__name--bold {
    font-weight: bold;
}

.discussion-item__frame {
    overflow: hidden;
    position: relative;
}

.discussion-item__frame--closed {
    height: 70px;
}

.discussion-item__frame--opened {
    height: auto;
}

.discussion-item__opener-container {
    padding: 8px 0 0;
    display: flex;
    justify-content: flex-end;
}

.discussion-item__frame:after {
    content: '';
    display: block;
    width: 100%;
    height: 44px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    display: none;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 3%, #ffffff);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 3%, var(--item_background));
}

.discussion-item__frame--level-2:after {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 3%, #ebedf0);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 3%, var(--item_level_2_background));
}

.discussion-item__frame--shaded:after {
    display: block;
}

.comment-form {
    padding: 16px 0 0;
    display: flex;
}

.comment-form__textarea {
    height: 100px;
}

.comment-form__inner {
    flex: 1 1;
    display: flex;
    overflow: hidden;
}

.comment-form__main {
    flex: 1 1;
    overflow: hidden;
}

.comment-form__avatar {
    margin: 0 8px 0 0;
    flex-shrink: 0;
}

.comment-form__submit.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.comment-form__attach {
    width: 100%;
    height: 100%;
}

.comment-form__attach input {
    display: none;
}

.comment-form-bar {
    padding: 5px 0 0 8px;
}

.comment-form-bar__item {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    margin: 0 0 4px;
}

.comment-form-bar__item:last-child {
    margin: 0;
}

.comment-form__attached-files {
    padding: 8px 0 0;
}

.discussion-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 0 30px;
}

.discussion-empty__icon {
    margin: 0 0 8px;
}

.discussion-empty__title {
    font-size: 16px;
    line-height: 24px;
    color: var(--text_color);
    text-align: center;
}

.rates {
    display: flex;
    align-items: center;
}

.rates__item {
    margin: 0 16px 0 0;
}

.rater__item:last-child {
    margin: 0;
}

.rate-item {
    display: flex;
    align-items: center;
}

.rate-item span {
    font-size: 15px;
    display: block;
    margin: 0 0 0 4px;
    color: var(--text_color);
}

.question {
    padding: 10px 0 58px;
    position: relative;
}

.question-updater {
    position: fixed;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    border-radius: 20px;
    top: 70px;
    z-index: 100;
}

:root {
    --form_title_color: #000000;
}

body[scheme="client_dark"] {
    --form_title_color: #e1e3e6;
}

.form {
    padding: 16px 16px 70px;
}

.form__files {
    margin: 0 0 16px;
}

.form__title {
    color: #000000;
    color: var(--form_title_color);
    font-size: 18px;
    line-height: 28px;
    margin: 0 0 10px;
}

.form__textarea {
    margin: 0 0 16px;
    height: 200px;
}

.form__file {
    margin: 0 0 16px;
}

.form-progress {
    padding: 8px;
}

.form-progress__title {
    color: #858585;
    font-size: 12px;
    line-height: 18px;
}

.form-progress__line {
    width: 100%;
    height: 8px;
    transition: all 0.3s;
    border-radius: 3px;
}

.form-progress__line--red {
    background: #e64646;
}

.form-progress__line--green {
    background: #4bb34b;
}

.form__submit[disabled] {
    background: #cccccc;
    opacity: 1;
    cursor: not-allowed;
}

.form__loader.Spinner {
    color: #ffffff;
}

.invalidated-files-popup {
    font-size: 14px;
    line-height: 20px;
}

.invalidated-files-popup ul {
    margin: 0;
    padding: 0 0 0 12px;
}

.invalidated-files-popup li {
    list-style: none;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.invalidated-files-popup li span {
    max-width: 100%;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.invalidated-files-popup__titles {
    margin: 0 0 6px;
}

.invalidated-files-popup__cross {
    margin: 1px 4px 0 0;
}

.invalidated-files-popup__section {
    margin: 0 0 8px;
}

.invalidated-files-popup__section:last-child {
    margin: 0;
}

.not-all-files-uploaded-popup {
    font-size: 14px;
    line-height: 20px;
}

.invalidated-question-popup ul {
    margin: 0;
    padding: 0;
    text-align: left;
}

.invalidated-question-popup ul li {
    list-style: none;
    padding: 0;
    margin: 0 0 4px;
    color: #e64646;
}

.invalidated-question-popup ul li:last-child {
    margin: 0;
}

.submit-phone-suggestion-modal {
    padding: 0 16px 32px;
}

.submit-phone-suggestion-modal__text {
    font-size: 13px;
    line-height: 16px;
    color: #5f5d5d;
}

.submit-phone-suggestion-modal__control {
    margin: 0 0 16px;
}

.submit-phone-suggestion-modal__input:disabled {
    background: var(--control_background);
    color: var(--control_color);
    border: 1px solid var(--control_border_color);
    cursor: text;
    opacity: 1;
}

.submit-phone-suggestion-modal__control:last-child {
    margin: 0;
}

.submit-phone-suggestion-modal__submit[disabled] {
    background: #cccccc;
    opacity: 1;
    cursor: not-allowed;
}

.tutorial,
.tutorial__slide,
.tutorial__gallery {
    height: 100%;
}

.tutorial__slide {
    background-repeat: no-repeat;
    background-size: cover;
    padding: 60px 16px 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
}

.tutorial__gallery .Gallery__bullets {
    bottom: 80px;
}

.tutorial__icon {
    width: 100px;
    height: 100px;
    margin: 0 auto 50px;
}

.tutorial__icon img {
    width: 100%;
    height: 100%;
}


.tutorial__text {
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    line-height: 24px;
}

.tutorial__logo {
    width: 170px;
    margin: 0 auto 40px;
}

.tutorial__logo img {
    width: 100%;
}

.tutorial__control {
    text-align: center;
    color: #ffffff;
    width: 100%;
    font-size: 17px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

