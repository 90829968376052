:root {
    --form_title_color: #000000;
}

body[scheme="client_dark"] {
    --form_title_color: #e1e3e6;
}

.form {
    padding: 16px 16px 70px;
}

.form__files {
    margin: 0 0 16px;
}

.form__title {
    color: var(--form_title_color);
    font-size: 18px;
    line-height: 28px;
    margin: 0 0 10px;
}

.form__textarea {
    margin: 0 0 16px;
    height: 200px;
}

.form__file {
    margin: 0 0 16px;
}

.form-progress {
    padding: 8px;
}

.form-progress__title {
    color: #858585;
    font-size: 12px;
    line-height: 18px;
}

.form-progress__line {
    width: 100%;
    height: 8px;
    transition: all 0.3s;
    border-radius: 3px;
}

.form-progress__line--red {
    background: #e64646;
}

.form-progress__line--green {
    background: #4bb34b;
}

.form__submit[disabled] {
    background: #cccccc;
    opacity: 1;
    cursor: not-allowed;
}

.form__loader.Spinner {
    color: #ffffff;
}
