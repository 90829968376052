.files-list {
    padding: 0;
}

.files-list__item {
    margin: 0 0 8px;
}

.files-list__item:last-child {
    margin-bottom: 0;
}
