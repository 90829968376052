:root {
    --card_background: #ffffff;
    --detail_form_separator_color: #e6e6e6;
}

body[scheme="client_dark"] {
    --card_background: #19191a;
    --detail_form_separator_color: #313233;
}

.question-card {
    padding: 20px 16px 24px;
    background: var(--card_background);
    margin: 0 0 24px;
}

.question-card__title {
    font-size: 22px;
    font-weight: bold;
    line-height: 28px;
    margin: 0 0 8px;
    color: var(--text_color);
}

.question-card__content {
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 16px;
}

.client-detail__content--tmp,
.question-card__content--tmp {
    padding: 0 16px;
    position: absolute;
    left: -999999999;
    visibility: hidden;
    z-index: -9999999;
}

.client-detail__inner,
.question-card__frame {
    overflow: hidden;
    position: relative;
}

.question-card__files {
    margin: 0 0 16px;
}

.question-card__frame--closed {
    height: 200px;
}

.client-detail__inner--opened,
.question-card__frame--opened {
    height: auto;
}

.client-detail__inner:after,
.question-card__frame:after {
    content: '';
    display: block;
    width: 100%;
    height: 44px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    display: none;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 3%, var(--card_background));
}

.client-detail__inner--shaded:after,
.question-card__frame--shaded:after {
    display: block;
}

.client-detail__opener-container,
.question-card__opener-container {
    display: flex;
    justify-content: flex-end;
    padding: 4px 0 0;
}

.detail-form {
    margin: 16px 0 0;
    padding: 16px 0 0;
    border-top: 1px solid var(--detail_form_separator_color);
}

.detail-form__title {
    font-size: 16px;
    line-height: 24px;
    color: var(--text_color);
    margin: 0 0 8px;
}

.detail-form__file,
.detail-form__files {
    margin: 0 0 16px;
}

.detail-form__submit[disabled] {
    opacity: 1;
    background: #cccccc;
    cursor: not-allowed;
}

.detail-form__textarea {
    height: 100px;
    margin: 0 0 16px;
}

.detail-form__progress {
    padding: 4px 8px;
}

.detail-form__loader.Spinner {
    color: #ffffff;
}

.client-details {
    margin: 0 0 16px;
    padding: 0;
}

.client-details__item {
    margin: 0 0 16px;
}

.client-detail {
    list-style: none;
    color: var(--text_color);
}

.client-detail__title {
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 8px;
    color: var(--text_color);
}

.client-detail__content {
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 8px;
}

.client-detail__meta {
    color: #909499;
    font-size: 14px;
}

.client-detail__files {
    margin: 0 0 16px;
}

.client-detail__inner--closed {
    height: 70px;
}
