.attached-files__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 -8px;
    padding: 0;
}

.attached-files__file {
    margin: 0 8px 8px 0;
    max-width: 100%;
    box-sizing: border-box;
}
