.question {
    padding: 10px 0 58px;
    position: relative;
}

.question-updater {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 20px;
    top: 70px;
    z-index: 100;
}
