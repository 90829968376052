:root {
    --item_background: #ffffff;
    --item_separator_color: #e6e6e6;
}

body[scheme="client_dark"] {
    --item_background: #19191a;
    --item_separator_color: #313233;
}

.question-item {
    background: var(--item_background);
    padding: 20px 16px 24px;
    border-bottom: 1px solid var(--item_separator_color);
    color: var(--text_color);
    cursor: pointer;
}

.question-item:last-child {
    border-bottom: none;
}

.question-item__title {
    margin: 0 0 8px;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
}

.question-item__text {
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 10px;
    color: var(--text_color);
}

.question-item__answers {
    font-size: 14px;
    color: #a9a9a9;
}
