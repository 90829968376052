.questions {
    list-style: none;
    margin: 0;
    padding: 10px 0 10px;
}

.questions-more {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
}

.questions-more__link {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
