.rate-item {
    display: flex;
    align-items: center;
}

.rate-item span {
    font-size: 15px;
    display: block;
    margin: 0 0 0 4px;
    color: var(--text_color);
}
