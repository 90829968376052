.rates {
    display: flex;
    align-items: center;
}

.rates__item {
    margin: 0 16px 0 0;
}

.rater__item:last-child {
    margin: 0;
}
