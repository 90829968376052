:root {
    --item_background: #ffffff;
    --item_level_2_background: #ebedf0;
}

body[scheme="client_dark"] {
    --item_background: #19191a;
    --item_level_2_background: #0a0a0a;
}

.discussion-item {

}

.discussion-item__main--highlighted {
    background: var(--item_background);
}

.discussion-item__header {
    margin: 0 0 5px;
    padding: 6px 0 0;
    display: flex;
    align-items: center;
}

.discussion-item__inner {
    padding: 0 16px 24px;
}

.discussion-item__content {
    font-size: 16px;
    line-height: 24px;
    color: var(--text_color);
    margin: 0 0 8px;
}

.discussion-item__files {
    margin: 0 0 16px;
}

.discussion-item__content--tmp {
    padding: 0 16px;
    position: absolute;
    left: -999999999;
    visibility: hidden;
    z-index: -9999999;
}

.discussion-item__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.discussion-item__footer-left {
    display: flex;
    align-items: center;
}

.discussion-item__meta {
    font-size: 14px;
    color: #909499;
}

.discussion-item__reply {
    margin: 0 0 0 10px;
}

.discussion-item__children .discussion-item__inner {
    padding-left: 24px;
}

.discussion-item__children .discussion-item__header {
    padding-left: 16px;
}

.discussion-item__sub-arrow {
    transform: rotate(180deg);
    margin: 0 -5px;
}

.discussion-item__name--bold {
    font-weight: bold;
}

.discussion-item__frame {
    overflow: hidden;
    position: relative;
}

.discussion-item__frame--closed {
    height: 70px;
}

.discussion-item__frame--opened {
    height: auto;
}

.discussion-item__opener-container {
    padding: 8px 0 0;
    display: flex;
    justify-content: flex-end;
}

.discussion-item__frame:after {
    content: '';
    display: block;
    width: 100%;
    height: 44px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    display: none;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 3%, var(--item_background));
}

.discussion-item__frame--level-2:after {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 3%, var(--item_level_2_background));
}

.discussion-item__frame--shaded:after {
    display: block;
}

.comment-form {
    padding: 16px 0 0;
    display: flex;
}

.comment-form__textarea {
    height: 100px;
}

.comment-form__inner {
    flex: 1;
    display: flex;
    overflow: hidden;
}

.comment-form__main {
    flex: 1;
    overflow: hidden;
}

.comment-form__avatar {
    margin: 0 8px 0 0;
    flex-shrink: 0;
}

.comment-form__submit.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.comment-form__attach {
    width: 100%;
    height: 100%;
}

.comment-form__attach input {
    display: none;
}

.comment-form-bar {
    padding: 5px 0 0 8px;
}

.comment-form-bar__item {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    margin: 0 0 4px;
}

.comment-form-bar__item:last-child {
    margin: 0;
}

.comment-form__attached-files {
    padding: 8px 0 0;
}
