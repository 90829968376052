.attached-file {
    list-style: none;
    background: #e8e8e8;
    border-radius: 15px;
    height: 24px;
    padding: 0 0 0 8px;
    position: relative;
    overflow: hidden;
}

.attached-file__inner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    position: relative;
}

.attached-file__name {
    color: #656565;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
}

.attached-file__delete {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #afafaf;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin: 0 2px 0 6px;
    cursor: pointer;
}

.attached-file__progress {
    position: absolute;
    width: 30%;
    height: 100%;
    left: 0;
    top: 0;
    animation-duration: 1.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: wave;
    background: rgba(0, 57, 115, 0.17);
    background: linear-gradient(to right, rgba(0,57,115,.17) 8%, rgba(255, 255, 255, 0.3) 18%, rgba(0,57,115,.17) 33%);
    background-size: 800px 104px;
}

.attached-file--done .attached-file__progress {
    background: #4bb34b52;
    animation: none;
}

.attached-file--done .attached-file__name {
    color: #858585;
}

.attached-file--error .attached-file__progress {
    background: #f33b3b85;
}

.attached-file--error .attached-file__name {
    color: #ffffff;
}

.attached-file--small {
    height: 20px;
}

.attached-file--small .attached-file__delete {
    width: 17px;
    height: 17px;
}

.attached-file--small .attached-file__name {
    font-size: 11px;
}
